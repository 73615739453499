import React, { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BusinessUnitParamContext } from '../BusinessUnit';
import forbidden from '../../../icons/forbidden.svg';
import { countRtpBusinessUnit, RtpBusinessUnitCountType } from '../../../utils/count-rtp-business-unit';
import { countPolicyType, PolicyCountType } from '../../../utils/count-policy-type';

const ImpactCard = () => {
  const {
    result: { data, entryPointId, subEntryPointId, policy }
  } = useContext(BusinessUnitParamContext);

  const countBusinessUnit: RtpBusinessUnitCountType = useMemo(() => {
    let unit = data.impacts.businessUnit.find((u) => u.name === entryPointId);
    if (subEntryPointId && unit) {
      unit = unit.children.find((u) => u.name === subEntryPointId);
    }
    if (unit) {
      return countRtpBusinessUnit(unit);
    }
    return {
      'Business Units': 10,
      'Procedures/Processes': 0,
      RTPs: 0
    };
  }, [data.impacts.businessUnit, entryPointId, subEntryPointId]);

  const countPolicy: PolicyCountType = useMemo(() => {
    let unit;
    const framework = data.impacts.framework.find((u) => u.name === entryPointId);
    if (subEntryPointId && framework) {
      unit = framework.policies.find((u) => u.name === subEntryPointId);
    }
    if (unit) {
      return countPolicyType(unit, true);
    }
    const policyCount: PolicyCountType = {
      Policies: 0,
      'Procedures/Processes': 0,
      RTPs: 0
    };

    return policyCount;
  }, [data.impacts.framework, entryPointId, subEntryPointId]);

  const impactDataCount = useMemo(() => {
    if (policy) {
      return countPolicy;
    } else {
      return countBusinessUnit;
    }
  }, [countBusinessUnit, countPolicy, policy]);

  return (
    <Box
      sx={{
        background: '#fff',
        boxShadow: '0 3px 2px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        flexBasis: '40%',
        padding: '18px 32px'
      }}
    >
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '22px',
          borderBottom: '1px solid #BCBCBC',
          paddingBottom: '10px',
          marginBottom: '27px'
        }}
      >
        Impacts
      </Typography>
      <Box>
        {Object.entries(impactDataCount).map((impact: any, index: number) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              ...(index !== Object.entries(impactDataCount).length - 1 && { marginBottom: '32px' })
            }}
          >
            <Box>{impact[0]}</Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', minWidth: 70 }}>
              <img src={forbidden} alt="warn" />
              <Box sx={{ fontWeight: '600', fontSize: '24px', marginLeft: '10px' }}>
                {impact[1] > 0 ? impact[1] : 1}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImpactCard;
