import React, { useState } from 'react';
import styles from './styles.module.css';
import { PolicyDataStructure, RtpDataStructure } from '../../interfaces/Rtp';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { VIZ_PREFIX } from '../../utils/constants';

// eslint-disable-next-line
interface ImpactCardProps {
  policies: Array<PolicyDataStructure>;
}

type FilterType = 'Upstream RTPs' | 'Downstream RTPs' | '';

const ImpactCard = ({ rtp }: { rtp: RtpDataStructure }) => {
  const [filter, setFilter] = useState<FilterType>('Downstream RTPs');
  console.log(filter);

  return (
    <div className={styles.card}>
      <h3>Other Impacts</h3>
      <hr className={styles.hr} />
      <div className={styles.filter}>
        <span className={styles.view}>VIEW:</span>
        <RadioGroup
          row
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilter(event.target.value as FilterType);
          }}
        >
          <FormControlLabel
            value="Upstream RTPs"
            control={<Radio color="default" size="small" />}
            label="Upstream RTPs"
          />
          <FormControlLabel
            value="Downstream RTPs"
            control={<Radio color="default" size="small" checked={filter === 'Downstream RTPs'} />}
            label="Downstream RTPs"
          />
        </RadioGroup>
      </div>
      {filter === 'Upstream RTPs' ? (
        <iframe
          className={styles.iframe}
          src={VIZ_PREFIX + '/depViewer.html?entryPoints=' + rtp.entryPointId + '&depViewMode=rtpParents'}
          title="Upstream RTPs"
        />
      ) : (
        <iframe
          className={styles.iframe}
          src={VIZ_PREFIX + '/depViewer.html?entryPoints=' + rtp.entryPointId + '&depViewMode=rtpChildren'}
          title="Downstream RTPs"
        />
      )}
    </div>
  );
};

export default ImpactCard;
