import React, { useState } from 'react';
import styles from './styles.module.css';
import Button from '@mui/material/Button';
import Warn from '../../../icons/warn.svg';
import { useNavigate } from 'react-router-dom';
import { Policy } from '../../../interfaces/LandingPage';
import Box from '@mui/material/Box';
import { countPolicyType, PolicyCountType } from '../../../utils/count-policy-type';

interface PolicyItemProps {
  data: Policy;
  groupName: string;
}

const PolicyItem: React.FC<PolicyItemProps> = (props) => {
  const navigate = useNavigate();
  const [policyCount] = useState<PolicyCountType>(countPolicyType(props.data));
  const { name } = props.data;

  const viewPolicy = () => {
    navigate(`/business-unit/${encodeURIComponent(props.groupName)}/${encodeURIComponent(name)}?policy=true`);
  };

  return (
    <Box>
      <div className={styles.item}>
        <div className={styles.description}>
          <h4>{name}</h4>
          <p className={styles.snippet}>
            {Object.entries(policyCount)
              .filter((p) => p[1] !== 0)
              .map((p) => `${p[1]} ${p[0]}`)
              .join(' / ')}
          </p>
        </div>
        <div>
          <img src={Warn} alt="warn" className={styles.warnIcon} />
          <Button sx={{ textTransform: 'capitalize', color: 'black' }} onClick={viewPolicy}>
            View
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default PolicyItem;
