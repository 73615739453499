import React, { useState } from 'react';
import styles from './styles.module.css';
import Button from '@mui/material/Button';
import Warn from '../../../icons/warn.svg';
import { useNavigate } from 'react-router-dom';
import { BasicPolicyDataStructure } from '../../../interfaces/LandingPage';
import { countBusinessUnitType, BusinessUnitCountType } from '../../../utils/count-business-unit-type';
import Box from '@mui/material/Box';

interface BusinessUnitItemProps {
  data: BasicPolicyDataStructure;
  groupName: string;
}

const BusinessUnitItem: React.FC<BusinessUnitItemProps> = (props) => {
  const navigate = useNavigate();
  const [businessUnitCount] = useState<BusinessUnitCountType>(countBusinessUnitType(props.data));
  const { name } = props.data;

  const viewBusinessUnit = () => {
    navigate(`/business-unit/${encodeURIComponent(props.groupName)}/${encodeURIComponent(name)}`);
  };

  return (
    <Box sx={{ paddingRight: '28px' }}>
      <div className={styles.item}>
        <div className={styles.description}>
          <h4>{name}</h4>
          <p className={styles.snippet}>
            {Object.entries(businessUnitCount)
              .filter((p) => p[1] !== 0)
              .map((p) => `${p[1]} ${p[0]}`)
              .join(' / ')}
          </p>
        </div>
        <div>
          <img src={Warn} alt="warn" className={styles.warnIcon} />
          <Button sx={{ textTransform: 'capitalize', color: 'black' }} onClick={viewBusinessUnit}>
            View
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default BusinessUnitItem;
