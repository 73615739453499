import React from 'react';
import styles from './style.module.css';
import Warn from '../../../icons/emptywarn.svg';

interface ImpactLabelProps {
  number: string | number;
}

export default function ImpactLabel(props: ImpactLabelProps) {
  const { number } = props;
  return (
    <div className={styles.impactLabelSpan}>
      <img src={Warn} alt="Warning label" />
      <span>{number} Impacted</span>
    </div>
  );
}
