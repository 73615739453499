import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MiniScopeCard from './MiniScopeCard/MiniScopeCard';
import Box from '@mui/material/Box';
import { useQuery } from '../../hooks/use-query';
import { DEFAULT_ORG_ID, DEFAULT_SCOPE_ID, SCOPE_URL } from '../../utils/constants';
import axios from 'axios';
import Loading from '../Loading/Loading';
import ErrorPage from '../ErrorPage/ErrorPage';
import AppLayout from '../Layouts/AppLayout';
import { LandingPageDataStructure, Proc } from '../../interfaces/LandingPage';
import ImpactCard from './ImpactCard/ImpactCard';
import RtpCard from './RtpCard/RtpCard';

interface ProcPageResultStructure {
  generalData: LandingPageDataStructure;
  procData: Proc;
}

interface ProcPageContextStructure {
  result: ProcPageResultStructure;
  setResult?: (res: ProcPageResultStructure) => void;
}

const defaultProcPageResult: ProcPageResultStructure = {
  generalData: {
    organisation: {
      name: '',
      logoUrl: '',
      licenseTypes: [],
      jurisdictions: [],
      headlines: {}
    },
    scope: {
      name: '',
      description: '',
      effectiveDate: '',
      primarySource: '',
      otherSources: []
    },
    impacts: {
      framework: [],
      businessUnit: []
    }
  },
  procData: {
    name: '',
    type: '',
    rtps: []
  }
};

export const ProcPageParamContext = React.createContext<ProcPageContextStructure>({
  result: defaultProcPageResult
});

const ProcPage = () => {
  const navigate = useNavigate();
  const { procId } = useParams();
  const [result, setResult] = useState<ProcPageResultStructure>(defaultProcPageResult);
  const query = useQuery();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initData = async () => {
      if (!procId) {
        navigate('/');
        return;
      }

      const scopeId = query.get('scopeId') ?? DEFAULT_SCOPE_ID;
      const orgId = query.get('orgId') ?? DEFAULT_ORG_ID;

      try {
        setLoading(true);
        const { data: generalData } = await axios.get(`${SCOPE_URL}${scopeId}/${orgId}`);
        const { data: procData } = await axios.get(`${SCOPE_URL}${scopeId}/${orgId}/proc/${procId}`);
        setResult({
          generalData,
          procData
        });
      } catch (e: any) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    initData().then();
  }, [navigate, procId, query]);

  return (
    <AppLayout>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorPage message={error} />
      ) : (
        <ProcPageParamContext.Provider value={{ result, setResult }}>
          <div>
            <Box sx={{ display: 'flex', width: '100%', marginBottom: '16px' }}>
              <MiniScopeCard />
              <ImpactCard />
            </Box>
            <RtpCard />
          </div>
        </ProcPageParamContext.Provider>
      )}
    </AppLayout>
  );
};

export default ProcPage;
