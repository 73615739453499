import React, { useState, useContext } from 'react';
import styles from './styles.module.css';
import { ParamContext } from '../RtpBoard';
import Minus from '../../icons/minus.svg';
import Plus from '../../icons/plus.svg';
import { Box, Typography } from '@mui/material';
import { DataModelParaStructure } from '../../interfaces/Rtp';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';

const options = ['Obligatory', 'Permitted', 'Forbidden', 'Excused', 'Is', 'IsNot'];
export interface UserInputProps extends DataModelParaStructure {
  prefix: string;
  $ref?: number;
}
const UserInput = ({ id, type, description, children, prefix, ...props }: UserInputProps) => {
  const { result, setResult, findRef } = useContext(ParamContext);
  const preDefinedAnswer = result[id] || '';
  const ref = type ? null : findRef && findRef(props['$ref'] as number);

  const [open, setOpen] = useState<boolean>(false);
  const [theDate, setTheDate] = useState<Date>((preDefinedAnswer as unknown as Date) || undefined);

  const childrenBoard = !children?.length
    ? null
    : children.map((child, index) => (
        <UserInput {...child} prefix={`${prefix}.${index + 1}`} key={index} id={child.id} />
      ));

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setResult &&
      setResult({
        ...result,
        [id]: event.target.value
      });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResult &&
      setResult({
        ...result,
        [id]: event.target.value
      });
  };

  const handleDateChange = (date: any) => {
    setTheDate(date);
    setResult &&
      setResult({
        ...result,
        [id]: date
      });
  };

  return (
    <Box mb={1.25}>
      <Box display="flex" alignItems="flex-end">
        {children?.length ? (
          <Box
            component="img"
            sx={{
              width: 50
            }}
            id={prefix}
            src={open ? Minus : Plus}
            alt="control-button"
            onClick={() => {
              setOpen((open) => !open);
            }}
          />
        ) : (
          <Box width="50px" />
        )}
        <Typography
          title={id === undefined ? ref?.id : id}
          sx={
            prefix === ''
              ? {
                  padding: 1.25,
                  backgroundColor: '#000000',
                  color: '#fff',
                  lineHeight: '36px',
                  width: '50px',
                  minWidth: '50px',
                  textAlign: 'center'
                }
              : {
                  padding: 1.25,
                  backgroundColor: '#5e5e5e',
                  color: '#fff',
                  lineHeight: '36px'
                }
          }
          id={children?.length ? undefined : prefix}
        >
          {prefix}
        </Typography>
        <div className={styles.descriptionBoard}>
          {description && (
            <div className={styles.description}>
              <p>{description}</p>
            </div>
          )}
          {type === 'ATOM' && !open && (
            <select name="" id="" value={preDefinedAnswer} onChange={handleSelectChange} className={styles.select}>
              <option value={''}>-</option>
              {options.map((option, index) => {
                return (
                  <option value={option} key={index}>
                    {option}
                  </option>
                );
              })}
            </select>
          )}

          {(type === 'NUMBER' || type === 'STRING' || type === 'DURATION') && !open && (
            <input
              type="text"
              value={preDefinedAnswer}
              placeholder="input"
              onChange={handleTypeChange}
              className={styles.input}
            />
          )}

          {type === 'DATE' && !open && (
            <DatePicker name="" value={theDate} onChange={handleDateChange} className={styles.datePicker} />
          )}

          {type === 'DATETIME' && !open && (
            <DateTimePicker
              value={theDate}
              onChange={handleDateChange}
              className={styles.dateTimePicker}
              disableClock={true}
            />
          )}
          {ref && (
            <div className={styles.description}>
              <p>
                <em>{ref.description}</em>
              </p>
            </div>
          )}
          {ref && (
            <Typography sx={{ width: '214px', paddingLeft: '12px' }}>
              <em>[References Already]</em>
            </Typography>
          )}
        </div>
      </Box>
      <Box sx={{ pl: 3.75 }}>{open && children?.length && childrenBoard}</Box>
    </Box>
  );
};

export default UserInput;
