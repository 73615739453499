import React from 'react';
import styles from './style.module.css';
import excused from '../../icons/excused.svg';
import forbidden from '../../icons/forbidden.svg';
import noResult from '../../icons/noresult.svg';
import obligatory from '../../icons/obligatory.svg';

interface ResultComponentProps {
  conclusion: string;
}

const getColor = (conclusion: string) => {
  switch (conclusion) {
    case 'Permitted':
      return styles.obligatory;
    case 'is':
      return styles.obligatory;
    case 'Obligatory':
      return styles.obligatory;
    case 'Excused':
      return styles.permitted;
    case 'IsNot':
      return styles.forbidden;
    case 'Forbidden':
      return styles.forbidden;
    case 'Inconclusive':
      return styles.empty;
    default:
      return styles.noResult;
  }
};
const ResultComponent = (props: ResultComponentProps) => {
  if (props.conclusion === 'Permitted') {
    return (
      <span className={`${styles.basicStyle} ${getColor(props.conclusion)}`}>
        <img src={obligatory} alt="tick" className={styles.icon} />
        <span className={styles.word}>{props.conclusion} </span>
      </span>
    );
  } else if (props.conclusion === 'Excused') {
    return (
      <span className={`${styles.basicStyle} ${getColor(props.conclusion)}`}>
        <img src={excused} alt="excused" className={styles.icon} />
        <span className={styles.word}>{props.conclusion} </span>
      </span>
    );
  } else if (props.conclusion === 'Is' || props.conclusion === 'Obligatory') {
    return (
      <span className={`${styles.basicStyle} ${getColor(props.conclusion)}`}>
        <img src={obligatory} className={styles.icon} alt="warn" />
        <span className={styles.word}>{props.conclusion} </span>
      </span>
    );
  } else if (props.conclusion === 'Forbidden' || props.conclusion === 'IsNot') {
    return (
      <span className={`${styles.basicStyle} ${getColor(props.conclusion)}`}>
        <img src={forbidden} className={styles.icon} alt="warn" />
        <span className={styles.word}>{props.conclusion} </span>
      </span>
    );
  } else {
    return (
      <span className={`${styles.basicStyle} ${getColor(props.conclusion)}`}>
        <img src={noResult} className={styles.icon} alt="warn" />
        <span className={styles.word}>{props.conclusion} </span>
      </span>
    );
  }
};

export default ResultComponent;
