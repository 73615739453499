import React, { useState } from 'react';
import { DataModelParaStructure, RtpDataStructure } from '../interfaces/Rtp';
import DescriptionCard from './description_card/DescriptionCard';
import ImpactCard from './otherImpactCard/ImpactCard';
import UserInputBoard from './userInput/UserInputBoard';
import TestCaseBoard from './testCaseBoard';
import axios from 'axios';
import { RESULT_URL } from '../utils/constants';
import { Box } from '@mui/material';

interface ResultStructure {
  [key: string]: string;
}

interface ContextStructure {
  result: ResultStructure;
  setResult?: (res: ResultStructure) => void;
  findRef?: (refId: number) => DataModelParaStructure | null;
}

export const ParamContext = React.createContext<ContextStructure>({
  result: {}
});

const RtpBoard = ({ rtp }: { rtp: RtpDataStructure }) => {
  const [result, setResult] = useState<ResultStructure>({});
  const { entryPointId } = rtp;
  const [predication, setPredication] = useState<string>('No Result');
  const [details, setDetails] = useState<object>();

  const getResult = (event: React.MouseEvent<HTMLElement>) => {
    console.log(JSON.stringify(result, null, 2));

    const filteredResult: ResultStructure = Object.fromEntries(
      Object.entries(result).filter(([_, value]) => value !== '')
    );

    axios
      .post(RESULT_URL + entryPointId, { ...filteredResult })
      .then((data) => {
        if (data.data) {
          setPredication(data.data);
        } else {
          setPredication('Invalid Response');
        }
      })
      .catch((e) => {
        console.log(e);
        setPredication('Inconclusive');
      });

    axios
      .post(RESULT_URL + entryPointId + '/details', { ...filteredResult })
      .then((data) => {
        if (data.data) {
          setDetails(data.data);
        } else {
          setDetails(data);
        }
      })
      .catch((e) => {
        console.log(e);
        setDetails(e);
      });
  };

  const findRef = (refId: number) => {
    let breadthFirstSearchArray: Array<DataModelParaStructure> = [];
    rtp.dataModel.parameters.forEach((kid) => breadthFirstSearchArray.push(kid));
    while (breadthFirstSearchArray.length > 0) {
      const firstElement = breadthFirstSearchArray.shift() as DataModelParaStructure;
      if (firstElement['$id'] === refId) {
        return firstElement;
      } else {
        firstElement?.children?.forEach((kid) => breadthFirstSearchArray.push(kid));
      }
    }

    return null;
  };

  return (
    <Box>
      <Box display="flex" boxShadow="0px 3px 2px 0px rgba(0, 0, 0, 0.15)" borderRadius="8px">
        <DescriptionCard rtp={rtp} />
        <ImpactCard rtp={rtp} />
      </Box>
      {rtp.dataModel.parameters != null && (
        <div>
          <ParamContext.Provider value={{ result, setResult, findRef }}>
            <UserInputBoard parameters={rtp.dataModel.parameters} />
          </ParamContext.Provider>
          <TestCaseBoard getResult={getResult} conclusion={predication.toString()} details={details} />
        </div>
      )}
    </Box>
  );
};

export default RtpBoard;
