import React from 'react';
import styles from './style.module.css';
import { LegislationDataStructure } from '../../interfaces/Rtp';

interface ShortContentItemProps {
  description: string;
  callback: (item: LegislationDataStructure) => void;
  dotted?: boolean;
  item: LegislationDataStructure;
  word: string;
}
const ShortContentItem = (props: ShortContentItemProps) => {
  return (
    <div className={props.dotted ? styles.itemGroupDotted : styles.itemGroup}>
      <div className={styles.descriptionContent}>{props.description}</div>
      <button className={styles.buttonBasic} onClick={() => props.callback(props.item)}>
        {props.word}
      </button>
    </div>
  );
};

export default ShortContentItem;
