import React from 'react';
import Box from '@mui/material/Box';
import Logo from '../../icons/logo.svg';

interface Props {
  children: JSX.Element;
}

const appLayoutStyle = {
  backgroundColor: '#f3f3f3',
  marginLeft: '96px'
};

const sidebarStyle = {
  position: 'fixed',
  left: 0,
  top: 0,
  background: '#000',
  width: '96px',
  height: '100%',
  padding: '24px 18px'
};

const containerStyle = {
  padding: '24px 32px',
  flexGrow: 1,
  maxWidth: '1380px',
  minHeight: '100vh',
  margin: 'auto'
};

const AppLayout: React.FC<Props> = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={appLayoutStyle}>
      <Box sx={sidebarStyle}>
        <Box
          component="img"
          sx={{
            width: '100%'
          }}
          alt="Logo"
          src={Logo}
        />
      </Box>
      <Box sx={containerStyle}>{children}</Box>
    </Box>
  );
};

export default AppLayout;
