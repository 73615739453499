import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DepartmentCard from './DepartmentCard/DepartmentCard';
import MiniScopeCard from './MiniScopeCard/MiniScopeCard';
import Box from '@mui/material/Box';
import ImpactCard from './ImpactCard/ImpactCard';
import ProcessProcedureCard from './ProcessProcedureCard/ProcessProcedureCard';
import { useQuery } from '../../hooks/use-query';
import { DEFAULT_ORG_ID, DEFAULT_SCOPE_ID, SCOPE_URL } from '../../utils/constants';
import axios from 'axios';
import Loading from '../Loading/Loading';
import ErrorPage from '../ErrorPage/ErrorPage';
import AppLayout from '../Layouts/AppLayout';
import { LandingPageDataStructure } from '../../interfaces/LandingPage';

interface BusinessUnitResultStructure {
  data: LandingPageDataStructure;
  entryPointId: string | undefined;
  subEntryPointId: string | undefined;
  policy: boolean;
  [key: string]: any;
}

interface BusinessUnitContextStructure {
  result: BusinessUnitResultStructure;
  setResult?: (res: BusinessUnitResultStructure) => void;
}

const defaultBusinessUnitResult: BusinessUnitResultStructure = {
  data: {
    organisation: {
      name: '',
      logoUrl: '',
      licenseTypes: [],
      jurisdictions: [],
      headlines: {}
    },
    scope: {
      name: '',
      description: '',
      effectiveDate: '',
      primarySource: '',
      otherSources: []
    },
    impacts: {
      framework: [],
      businessUnit: []
    }
  },
  entryPointId: undefined,
  subEntryPointId: undefined,
  policy: false
};

export const BusinessUnitParamContext = React.createContext<BusinessUnitContextStructure>({
  result: defaultBusinessUnitResult
});

const BusinessUnit = () => {
  const navigate = useNavigate();
  const { entryPointId, subEntryPointId } = useParams();
  const [result, setResult] = useState<BusinessUnitResultStructure>(defaultBusinessUnitResult);
  const query = useQuery();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!entryPointId) {
      navigate('/');
      return;
    }

    const scopeId = query.get('scopeId') ?? DEFAULT_SCOPE_ID;
    const orgId = query.get('orgId') ?? DEFAULT_ORG_ID;
    const isPolicy = query.get('policy');

    setLoading(true);
    axios
      .get(`${SCOPE_URL}${scopeId}/${orgId}`)
      .then((data) =>
        setResult({
          data: data.data,
          entryPointId: decodeURIComponent(entryPointId),
          policy: isPolicy === 'true',
          subEntryPointId: subEntryPointId && decodeURIComponent(subEntryPointId)
        })
      )
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [navigate, query, entryPointId, subEntryPointId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppLayout>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorPage message={error} />
      ) : (
        <BusinessUnitParamContext.Provider value={{ result, setResult }}>
          <div>
            <DepartmentCard />
            <Box sx={{ display: 'flex', width: '100%', marginBottom: '16px' }}>
              <MiniScopeCard />
              <ImpactCard />
            </Box>
            <ProcessProcedureCard />
          </div>
        </BusinessUnitParamContext.Provider>
      )}
    </AppLayout>
  );
};

export default BusinessUnit;
