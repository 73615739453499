import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { BusinessUnitParamContext } from '../BusinessUnit';
import { useNavigate } from 'react-router-dom';

const departmentCardStyle = {
  background: '#fff',
  boxShadow: '0 3px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  padding: '18px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '17px'
};

const DepartmentCard = () => {
  const { result } = useContext(BusinessUnitParamContext);
  const navigate = useNavigate();
  const [entryPointIdSelect, setEntryPointIdSelect] = React.useState(result.entryPointId);
  const [subEntryPointIdSelect] = React.useState(result.subEntryPointId || '');
  const { logoUrl } = result.data.organisation;

  const handleChangeFirstSelect = (event: SelectChangeEvent) => {
    if (result.policy) {
      setEntryPointIdSelect(event.target.value as string);
    } else {
      navigate(`/business-unit/${encodeURIComponent(event.target.value)}`);
    }
  };

  const handleChangeSecondSelect = (event: SelectChangeEvent) => {
    if (result.policy) {
      navigate(
        `/business-unit/${encodeURIComponent(entryPointIdSelect!)}/${encodeURIComponent(
          event.target.value
        )}?policy=true`
      );
    } else {
      navigate(`/business-unit/${encodeURIComponent(entryPointIdSelect!)}/${encodeURIComponent(event.target.value)}`);
    }
  };

  const generateEntryPointIdSelection = () => {
    if (result.policy) {
      return result.data.impacts.framework.map((policy) => policy.name);
    } else {
      return result.data.impacts.businessUnit.map((bu) => bu.name);
    }
  };

  const generateSubEntryPointIdSelection = () => {
    if (result.policy) {
      const frameworks = result.data.impacts.framework;
      const selectedFramework = entryPointIdSelect
        ? frameworks.find((framework) => framework.name === entryPointIdSelect)
        : frameworks[0];
      return selectedFramework?.policies.map((child) => child.name) || [];
    } else {
      const units = result.data.impacts.businessUnit;
      const selectedUnit = entryPointIdSelect ? units.find((unit) => unit.name === entryPointIdSelect) : units[0];
      return selectedUnit?.children.map((child) => child.name) || [];
    }
  };

  return (
    <Box sx={departmentCardStyle}>
      <Box
        sx={{
          height: 'fit-content',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '32px'
        }}
      >
        <Box component="img" alt="Organisation logo" sx={{ width: 75 }} src={logoUrl} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ whiteSpace: 'nowrap', marginRight: '16px' }}>Banking Dept</Box>
        <FormControl sx={{ minWidth: '210px', marginRight: '16px' }}>
          <Select value={entryPointIdSelect} onChange={handleChangeFirstSelect}>
            {generateEntryPointIdSelection().map((option: string, index: number) => (
              <MenuItem value={option} key={index}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {generateSubEntryPointIdSelection().length > 0 && (
          <FormControl sx={{ minWidth: '210px', marginRight: '34px' }}>
            <Select value={subEntryPointIdSelect} onChange={handleChangeSecondSelect}>
              {generateSubEntryPointIdSelection().map((option: string, index: number) => (
                <MenuItem value={option} key={index}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box sx={{ whiteSpace: 'nowrap' }}>BD-00000</Box>
      </Box>
    </Box>
  );
};

export default DepartmentCard;
