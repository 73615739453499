import React from 'react';
import styles from './style.module.css';

interface CountCardProps {
  number: number | string;
  name: string;
}

export default function CountCard(props: CountCardProps) {
  const { number, name } = props;
  return (
    <div className={styles.countCard}>
      <span className={styles.number}>
        {number} <br />
      </span>
      <span className={styles.name}>{name}</span>
    </div>
  );
}
