import React, { useState } from 'react';
import styles from './styles.module.css';
import BusinessUnitItemGroup from './BusinessUnitItemGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ImpactDataStructure } from '../../../interfaces/LandingPage';
import PolicyItemGroup from './PolicyItemGroup';

type FilterType = 'Business Unit Level' | 'Policy Level' | '';

const ImpactCard: React.FC<ImpactDataStructure> = (props) => {
  const [filter, setFilter] = useState<FilterType>('Business Unit Level');

  return (
    <div className={styles.card}>
      <h3>Impact Details</h3>
      <hr className={styles.hr} />
      <div className={styles.filter}>
        <span className={styles.view}>VIEW:</span>
        <RadioGroup
          row
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilter(event.target.value as FilterType);
          }}
        >
          <FormControlLabel
            value="Business Unit Level"
            control={<Radio color="default" size="small" checked={filter === 'Business Unit Level'} />}
            label="Business Unit Level"
          />
          <FormControlLabel
            value="Policy Level"
            control={<Radio color="default" size="small" />}
            label="Policy Level"
          />
        </RadioGroup>
      </div>
      {filter === 'Business Unit Level'
        ? props.businessUnit.map((item, index) => {
            return <BusinessUnitItemGroup key={index} data={item} />;
          })
        : props.framework.map((item, index) => {
            return <PolicyItemGroup key={index} data={item} />;
          })}
    </div>
  );
};

export default ImpactCard;
