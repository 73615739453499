import React from 'react';
import { OrganisationDataStructure } from '../../../interfaces/LandingPage';
import CountCard from './CountCard';
import styles from './style.module.css';
import Box from '@mui/material/Box';
import { COUNT_CARD_PARTS } from '../../../utils/constants';

const OrganizationCard: React.FC<OrganisationDataStructure> = (props) => {
  const { name, licenseTypes, jurisdictions, headlines, logoUrl } = props;

  return (
    <div className={styles.cardBoard}>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '32px'
          }}
        >
          <Box component="img" alt="Organisation logo" sx={{ width: 75 }} src={logoUrl} />
        </Box>
        <div className={styles.organizationCardContainer}>
          <div className={styles.organizationCard__header}>
            <div className={styles.organizationCard__item}>Organisation Name</div>
            <div className={styles.organizationCard__item}>Jurisdictions</div>
            <div className={styles.organizationCard__item}>LicenseTypes</div>
          </div>
          <div className={styles.organizationCard__content}>
            <div className={styles.organizationCard__item}>{name}</div>
            <div className={styles.organizationCard__item}>{jurisdictions.join(', ')}</div>
            <div className={styles.organizationCard__item}>{licenseTypes.join(', ')}</div>
          </div>
        </div>
      </Box>
      <div className={styles.countCardContainer}>
        {COUNT_CARD_PARTS.map((item, index) => {
          return <CountCard number={headlines[item]} name={item} key={index} />;
        })}
      </div>
    </div>
  );
};

export default OrganizationCard;
