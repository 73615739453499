import React, { useEffect, useState } from 'react';
import { RTP_LIST_URL } from '../../utils/constants';
import styles from './styles.module.css';

interface RTPSelectorProps {
  entrypoint: string;
  onChange: (value: string) => void;
}

const RtpSelector: React.FC<RTPSelectorProps> = ({ entrypoint, onChange }) => {
  const [options, setOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>(entrypoint);

  useEffect(() => {
    fetch(RTP_LIST_URL)
      .then((response) => response.json())
      .then((data) => setOptions(data))
      .catch((error) => console.log(error))
      .finally(() => setSelectedOption(entrypoint));
  }, [entrypoint]);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedOption(event.target.value);
    onChange(event.target.value);
  }

  return (
    <div className={styles.background}>
      <div className={styles.card}>
        <h3>Select RTP</h3>
        <hr className={styles.hr} />
        <select name="" id="" value={selectedOption} onChange={handleChange} className={styles.select}>
          <option value={''}>-</option>
          {options.map((str) => (
            <option key={str} value={str}>
              {str}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default RtpSelector;
