export const API_PREFIX = process.env.APT_PREFIX || 'https://kgapp.realtalogic.com/api/';
export const VIZ_PREFIX =
  process.env.VIZ_PREFIX || 'https://mango-mushroom-0d922ec00-10.eastasia.1.azurestaticapps.net';

export const RTP_URL = process.env.RTP_URL || `${API_PREFIX}Explore/entryPoint/detail/`;
export const SOURCE_TEXT_URL = process.env.SOURCE_TEXT_URL || `${API_PREFIX}Explore/sourceText/`;
export const RESULT_URL = process.env.RESULT_URL || `${API_PREFIX}Reasoning/conclusion/`;
export const SCOPE_URL = process.env.SCOPE_URL || `${API_PREFIX}Explore/scope/`;
export const RTP_LIST_URL = process.env.RTP_LIST_URL || `${API_PREFIX}Explore/entryPoints/`;

export const DEFAULT_SCOPE_ID = 'ddo';
export const DEFAULT_ORG_ID = 'rlbank';
export const COUNT_CARD_PARTS = ['Obligations Register', 'Business Units', 'Products', 'Policies'];
