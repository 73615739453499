import React, { useEffect, useState } from 'react';
import { RtpDataStructure } from '../interfaces/Rtp';
import RtpBoard from './RtpBoard';
import styles from './Rtp.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RTP_URL } from '../utils/constants';
import AppLayout from './Layouts/AppLayout';
import RTPSelector from './RtpSelector/RtpSelector';
import { Box } from '@mui/material';

const Rtp = () => {
  const [rtpData, setRtpData] = useState<RtpDataStructure>();
  const { entryPointId } = useParams();
  const navigate = useNavigate();

  function getRtpData(rtpId: string | undefined) {
    if (rtpId !== undefined) {
      axios
        .get(RTP_URL + rtpId)
        .then((data) => setRtpData(data.data))
        .catch((e) => console.error(e));
    }
  }

  function handleSelectChange(value: string) {
    getRtpData(value);
    navigate(`/rtp/${value}`, { replace: true });
  }

  useEffect(() => {
    getRtpData(entryPointId);
    document.title = `Realta Logic Regulatory Dashboard - ${entryPointId}`;
  }, [entryPointId]);

  return (
    <AppLayout>
      {!rtpData ? (
        <Box className={styles.mainBoard}>
          <RTPSelector entrypoint="" onChange={handleSelectChange} />
        </Box>
      ) : (
        <Box className={styles.mainBoard}>
          <RTPSelector entrypoint={rtpData.entryPointId} onChange={handleSelectChange} />
          <RtpBoard rtp={rtpData} />
        </Box>
      )}
    </AppLayout>
  );
};

export default Rtp;
