import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import Box from '@mui/material/Box';
import { SOURCE_TEXT_URL } from '../../utils/constants';
/*import { parse } from 'html-react-parser';*/
import Loading from '../Loading/Loading';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: '#eefaf8',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: '80vh',
  overflowY: 'scroll'
};

const parse = require('html-react-parser');

interface CustomizeModalProps {
  open: boolean;
  handleClose: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
  id: string;
  legalTextIds: Array<string>;
}
const CustomizeModal = (props: CustomizeModalProps) => {
  const [result, setResult] = useState<Array<ReactElement>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios.get(SOURCE_TEXT_URL + props.id).then((data) => {
      var parser = new DOMParser();
      var doc = parser.parseFromString(data.data.convertedHTML, 'text/html');
      let a: Array<ReactElement> = [];
      props.legalTextIds.forEach((item) => {
        const element = doc.getElementById(item);
        if (element) {
          var parsedRes = parse(element.outerHTML);
          a.push(parsedRes);
        }
      });
      setResult(a);
      setLoading(false);
    });
  }, [props.id, props.legalTextIds]);
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Box sx={style}>
        {loading && <Loading />}
        {!loading && result}
      </Box>
    </Modal>
  );
};

export default CustomizeModal;
