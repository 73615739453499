import React, { useEffect, useState } from 'react';
import { LandingPageDataStructure } from '../../interfaces/LandingPage';
import OrganizationCard from './organizationCard';
import styles from './style.module.css';
import ScopeCard from './scope_card';
import EnterpriseImpactCard from './enterpriseImpactCard';
import ImpactCard from './otherImpactCard/ImpactCard';
import { useQuery } from '../../hooks/use-query';
import { DEFAULT_ORG_ID, DEFAULT_SCOPE_ID, SCOPE_URL } from '../../utils/constants';
import axios from 'axios';
import Loading from '../Loading/Loading';
import ErrorPage from '../ErrorPage/ErrorPage';
import mockData from '../../landingPage.json';
import AppLayout from '../Layouts/AppLayout';

const initLandingPageData: LandingPageDataStructure = {
  organisation: {
    name: '',
    logoUrl: '',
    licenseTypes: [],
    jurisdictions: [],
    headlines: {}
  },
  scope: {
    name: '',
    description: '',
    effectiveDate: '',
    primarySource: '',
    otherSources: []
  },
  impacts: {
    framework: [],
    businessUnit: []
  }
};

const LandingPage = () => {
  const query = useQuery();
  const [landingPageData, setLandingPageData] = useState<LandingPageDataStructure>(initLandingPageData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const scopeId = query.get('scopeId') ?? DEFAULT_SCOPE_ID;
    const orgId = query.get('orgId') ?? DEFAULT_ORG_ID;

    setLoading(true);
    axios
      .get(`${SCOPE_URL}${scopeId}/${orgId}`)
      .then((data) => setLandingPageData(data.data))
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [query]);

  return (
    <AppLayout>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorPage message={error} />
      ) : (
        <div>
          <OrganizationCard {...landingPageData.organisation} />
          <ScopeCard {...landingPageData.scope} />
          <div className={styles.impactCardContainer}>
            {/* EnterpriseImpactCard uses mock data. */}
            <EnterpriseImpactCard {...mockData.impacts.impactHeadlines} />
            <ImpactCard {...landingPageData.impacts} />
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default React.memo(LandingPage);
