import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const loadingStyle = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export interface LoadingProps {
  global?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ global = true }) => {
  return (
    <Box
      sx={{
        ...loadingStyle,
        position: global ? 'fixed' : 'absolute',
        backgroundColor: global ? 'rgba(0, 0, 0, 0.2)' : 'unset'
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
