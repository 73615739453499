import React, { useState } from 'react';
import { RtpDataStructure, PossibleConclusion, LegislationDataStructure } from '../../interfaces/Rtp';
import styles from './style.module.css';
import ShortContentItem from './ShortContentItem';
import CustomizeModal from '../customizeModal/CustomizeModal';
import ResultComponent from '../resultComponent';
export const getColor = (conclusion: PossibleConclusion) => {
  switch (conclusion) {
    case 'Permitted':
      return styles.green;
    case 'Is':
      return styles.green;
    case 'Obligatory':
      return styles.yellow;
    case 'Excused':
      return styles.yellow;
    case 'IsNot':
      return styles.red;
    case 'Forbidden':
      return styles.red;
    default:
      return styles.red;
  }
};

interface ModalParamProps {
  id: string;
  legalTextIds: Array<string>;
}
const DescriptionCard = ({ rtp }: { rtp: RtpDataStructure }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [modalParam, setModalParam] = useState<ModalParamProps>({
    id: rtp.legislation[0].internalSource.id,
    legalTextIds: rtp.legislation[0].internalSource.legalTextIds
  });

  const showMoreCallBack = (item: LegislationDataStructure) => {
    setOpen((open) => !open);
    setModalParam({ id: item.internalSource.id, legalTextIds: item.internalSource.legalTextIds });
  };

  return (
    <div className={styles.descriptionCard}>
      <div className={styles.leftCard}>
        <h1>Regulatory Touch Point</h1>
        <div className={styles.cardSnippet}>
          <h3>Description</h3>
          <div className={styles.cardContent} dangerouslySetInnerHTML={{ __html: rtp.entryPointDescription }} />
        </div>

        <div className={styles.cardSnippet}>
          <div className={styles.cardHeader}>Source</div>
          {rtp.legislation.map((item, index) => (
            <ShortContentItem
              key={index}
              description={`${item.name} | ${item.ref}`}
              callback={showMoreCallBack}
              item={item}
              word={'Source'}
            />
          ))}
        </div>
      </div>
      <div className={styles.rightCard}>
        <div className={styles.possibleResults}>Possible Results</div>
        <div className={styles.conclusionRow}>
          {rtp.dataModel.possibleConclusions.map((item, index) => {
            return <ResultComponent conclusion={item} key={index} />;
          })}
        </div>
      </div>

      <CustomizeModal open={open} handleClose={handleClose} id={modalParam.id} legalTextIds={modalParam.legalTextIds} />
    </div>
  );
};

export default DescriptionCard;
