import React, { useState } from 'react';
import { ScopeDataStructure } from '../../../interfaces/LandingPage';
import styles from './style.module.css';
import Down from '../../../icons/down.svg';
import Right from '../../../icons/right.svg';
import IconButton from '@mui/material/IconButton';

const ScopeCard: React.FC<ScopeDataStructure> = (props) => {
  const { name, description, effectiveDate, primarySource, otherSources } = props;
  let str = description.split('</p>');
  str = str
    .filter((s) => s.length > 0)
    .map((s) => {
      return s.replace('<p>', '');
    });
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={styles.scopeCardContainer}>
      <div className={styles.scopeCard_leftContent}>
        <div className={styles.nameSnippet}>Scope | {name}</div>
        <div className={styles.descriptionContent}>
          {str.map((s, index) => {
            return (
              <p className={styles.descriptionParagraph} key={index}>
                {s}
              </p>
            );
          })}
        </div>
      </div>
      <div className={styles.scopeCard_rightContent}>
        <div className={styles.buttonGroup}>
          <div className={styles.buttonItem}>
            <span className={styles.sourceDescription}>Primary Source</span>
            <a target="_blank" rel="noreferrer" href={primarySource} className={styles.button}>
              View
            </a>
          </div>
          {otherSources.length > 0 && (
            <div>
              <div className={styles.buttonItem}>
                <span className={styles.sourceDescription}>Other Guidance</span>
                <IconButton sx={{ width: 28 }} color="primary" onClick={() => setOpen((open) => !open)}>
                  {<img alt="open" src={open ? Down : Right} />}
                </IconButton>
              </div>
              {open &&
                otherSources.map((otherSource, index) => (
                  <div className={styles.buttonItem} key={index}>
                    <span className={styles.otherSourceDescription}>{otherSource.name}</span>
                    <a target="_blank" rel="noreferrer" href={otherSource.url} className={styles.button}>
                      View
                    </a>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className={styles.comeIntoEffectCard}>
          Comes into effect: <strong>{effectiveDate}</strong>
        </div>
      </div>
    </div>
  );
};

export default ScopeCard;
