import { Policy } from '../interfaces/LandingPage';

export interface PolicyCountType {
  Policies: number;
  'Procedures/Processes': number;
  RTPs: number;
}

export const countPolicyType = (data: Policy, includeRtps?: boolean): PolicyCountType => {
  const result: PolicyCountType = {
    Policies: 0,
    'Procedures/Processes': 0,
    RTPs: 0
  };
  let rtpCount = 0;
  data.procs.forEach((proc) => {
    rtpCount += proc.rtps.length;
    result['Procedures/Processes']++;
  });
  if (includeRtps) {
    result.RTPs = rtpCount;
  }
  return result;
};
