import React from 'react';
import { ImpactHeadLinesDataStructure } from '../../../interfaces/LandingPage';
import styles from './style.module.css';
import ImpactLabel from './ImpactLabel';

const EnterpriseImpactCard: React.FC<ImpactHeadLinesDataStructure> = (props) => {
  return (
    <div className={styles.enterpriseImpactCardContainer}>
      <h1>Enterprise Impact</h1>
      <div className={styles.description}>New or Updated areas for assesment</div>
      <div className={styles.warningGroup}>
        <div className={styles.warningItem}>
          <span>Obligations</span>
          <ImpactLabel number={props['Obligations Register']} />
        </div>
        <div className={styles.warningItem}>
          <span>Frameworks</span>
          <ImpactLabel number={props['Frameworks']} />
        </div>
        <div className={styles.warningItem}>
          <span>Policies</span>
          <ImpactLabel number={props['Policies']} />
        </div>
        <div className={styles.warningItem}>
          <span>Business Units</span>
          <ImpactLabel number={props['Business Units']} />
        </div>
        <div className={styles.warningItem}>
          <span>Procedures/Processes</span>
          <ImpactLabel number={props['ProceduresProcesses']} />
        </div>
        <div className={styles.warningItem}>
          <span>Products</span>
          <ImpactLabel number={props['Products']} />
        </div>
      </div>
    </div>
  );
};

export default EnterpriseImpactCard;
