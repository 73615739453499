import React, { useState } from 'react';
import styles from './styles.module.css';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Warn from '../../../icons/warn.svg';
import Right from '../../../icons/right.svg';
import Down from '../../../icons/down.svg';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { BasicPolicyDataStructure } from '../../../interfaces/LandingPage';
import { countBusinessUnitType, BusinessUnitCountType } from '../../../utils/count-business-unit-type';
import BusinessUnitItem from './BusinessUnitItem';

interface BusinessUnitItemGroupProps {
  data: BasicPolicyDataStructure;
}

const BusinessUnitItemGroup: React.FC<BusinessUnitItemGroupProps> = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [businessUnitCount] = useState<BusinessUnitCountType>(countBusinessUnitType(props.data));
  const { name, children } = props.data;

  const viewBusinessUnit = () => {
    navigate(`/business-unit/${encodeURIComponent(name)}`);
  };

  return (
    <div className={styles.itemGroupDotted}>
      <div className={styles.item}>
        <div className={styles.description}>
          <h3>{name}</h3>
          <p className={styles.snippet}>
            {Object.entries(businessUnitCount)
              .filter((p) => p[1] !== 0)
              .map((p) => `${p[1]} ${p[0]}`)
              .join(' / ')}
          </p>
        </div>
        <div>
          <img src={Warn} alt="warn" className={styles.warnIcon} />
          <Button sx={{ textTransform: 'capitalize', color: 'black' }} onClick={viewBusinessUnit}>
            View
          </Button>
          {businessUnitCount['Business Units'] > 0 ? (
            <IconButton color="primary" onClick={() => setOpen((open) => !open)} sx={{ width: 28 }}>
              {<img alt="open" src={open ? Down : Right} />}
            </IconButton>
          ) : (
            <Box sx={{ display: 'inline-block', width: 28 }} />
          )}
        </div>
      </div>
      {open && (
        <Box>
          {children.map((proc, index) => (
            <BusinessUnitItem key={index} groupName={name} data={proc} />
          ))}
        </Box>
      )}
    </div>
  );
};

export default BusinessUnitItemGroup;
