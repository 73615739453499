import React from 'react';
import styles from './style.module.css';
import ResultComponent from '../resultComponent';
import { JsonViewer } from '@textea/json-viewer';

interface TestCaseBoardProps {
  getResult: (event: React.MouseEvent<HTMLElement>) => void;
  conclusion: string;
  details: object | undefined;
}
const TestCaseBoard = (props: TestCaseBoardProps) => {
  const { getResult } = props;
  return (
    <div className={styles.testCaseBoard}>
      <div className={styles.userInput_header}>
        <div className={styles.importButtonContainer}>
          <ResultComponent conclusion={props.conclusion} />
          <button
            className={styles.runTestCase}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              getResult(e);
            }}
          >
            Get Reasoning Result
          </button>
        </div>
      </div>
      <div className={styles.board}>{props.details && <JsonViewer value={props.details} />}</div>
    </div>
  );
};

export default TestCaseBoard;
