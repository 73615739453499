import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { BusinessUnitParamContext } from '../BusinessUnit';
import Typography from '@mui/material/Typography';
import { DEFAULT_ORG_ID, DEFAULT_SCOPE_ID, SCOPE_URL } from '../../../utils/constants';
import axios from 'axios';
import { useQuery } from '../../../hooks/use-query';
import Loading from '../../Loading/Loading';
import { BasicPolicyDataStructure, Policy, Proc } from '../../../interfaces/LandingPage';
import { countRtps } from '../../../utils/count-rtp';
import { useNavigate, useParams } from 'react-router-dom';

const processProcedureCardStyle = {
  background: '#fff',
  boxShadow: '0 3px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  flexGrow: 1,
  padding: '18px 32px'
};

const initProcessProcedureData: BasicPolicyDataStructure = {
  name: '',
  procs: [],
  children: []
};

const ProcessProcedureCard = () => {
  const query = useQuery();
  const params = useParams();
  const navigate = useNavigate();
  const {
    result: { entryPointId, subEntryPointId, policy }
  } = useContext(BusinessUnitParamContext);
  const [processProcedureData, setProcessProcedureData] = useState<BasicPolicyDataStructure>(initProcessProcedureData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const scopeId = query.get('scopeId') ?? DEFAULT_SCOPE_ID;
    const orgId = query.get('orgId') ?? DEFAULT_ORG_ID;
    const buId = subEntryPointId ? subEntryPointId : entryPointId;

    if (buId) {
      if (policy) {
        setLoading(true);
        axios
          .get(`${SCOPE_URL}${scopeId}/${orgId}/policy/${buId}`)
          .then((data) => setProcessProcedureData(data.data))
          .catch((e) => console.log(e.message))
          .finally(() => setLoading(false));
      } else {
        setLoading(true);
        axios
          .get(`${SCOPE_URL}${scopeId}/${orgId}/businessUnit/${buId}`)
          .then((data) => setProcessProcedureData(data.data))
          .catch((e) => console.log(e.message))
          .finally(() => setLoading(false));
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderData = (data: BasicPolicyDataStructure | Policy) => {
    if (policy) {
      return renderPolicyData(data as Policy);
    } else {
      return renderBusinessUnitData(data as BasicPolicyDataStructure);
    }
  };

  const renderBusinessUnitData = (businessUnit: BasicPolicyDataStructure) => {
    return (
      <Box>
        {generateHeadCard(businessUnit)}
        {businessUnit.procs.map((proc, index) => generatePCard(proc, index))}
        {businessUnit.children.map((child, index) => (
          <Box key={index}>{generateHeadCard(child, true)}</Box>
        ))}
      </Box>
    );
  };

  const renderPolicyData = (policyData: Policy) => {
    return (
      <Box>
        {generateHeadCard(policyData)}
        {policyData.procs.map((proc, index) => generatePCard(proc, index))}
      </Box>
    );
  };

  const generateHeadCard = (data: BasicPolicyDataStructure | Policy, isChild = false) => {
    const rtpCount = countRtps(data);
    return (
      <Box
        sx={{
          background: '#f8f8f8',
          border: '1px solid #dfdfdf',
          padding: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box>
          <Box sx={{ fontWeight: '600', fontSize: '16px', lineHeight: '20px' }}>{data.name}</Box>
          <Box sx={{ fontWeight: '500', fontSize: '10px', lineHeight: '12px', color: '#818181' }}>
            {Object.entries(rtpCount)
              .filter((rtp) => rtp[1] > 0)
              .map((rtp) => `${rtp[1]} ${rtp[0]}`)
              .join(' | ')}
          </Box>
        </Box>
        {isChild && (
          <Box onClick={() => navigateToProcPage(data.name)} sx={{ marginRight: '33px', cursor: 'pointer' }}>
            View
          </Box>
        )}
      </Box>
    );
  };

  const generatePCard = (proc: Proc, index: number) => (
    <Box
      key={index}
      sx={{
        background: '#f2f2f2',
        border: '1px solid #dfdfdf',
        padding: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box>
        <Box sx={{ fontWeight: '600', fontSize: '16px', lineHeight: '20px' }}>{proc.name}</Box>
        <Box sx={{ fontWeight: '500', fontSize: '10px', lineHeight: '12px', color: '#818181' }}>
          {proc.rtps.length} RTP
        </Box>
      </Box>
      <Box onClick={() => navigateToProcPage(proc.name)} sx={{ marginRight: '33px', cursor: 'pointer' }}>
        View
      </Box>
    </Box>
  );

  const navigateToProcPage = (name: string) => {
    const { entryPointId, subEntryPointId } = params;
    const url = subEntryPointId
      ? `/procs/${encodeURIComponent(entryPointId!)}/${encodeURIComponent(subEntryPointId)}/${encodeURIComponent(name)}`
      : `/procs/${encodeURIComponent(entryPointId!)}/${encodeURIComponent(name)}`;
    navigate(url);
  };

  return loading ? (
    <Loading global={false} />
  ) : (
    <Box sx={processProcedureCardStyle}>
      <Typography
        variant="h2"
        component="h2"
        sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '29px', marginBottom: '16px' }}
      >
        Processes & Procedures
      </Typography>
      <Box>{processProcedureData && renderData(processProcedureData)}</Box>
    </Box>
  );
};

export default ProcessProcedureCard;
