import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ProcPageParamContext } from '../ProcPage';

const RtpCard = () => {
  const navigate = useNavigate();
  const {
    result: { procData }
  } = useContext(ProcPageParamContext);

  const generateProcCard = () =>
    procData.rtps.map((rtp, index) => (
      <Box
        key={index}
        sx={{
          background: '#f2f2f2',
          border: '1px solid #dfdfdf',
          padding: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginRight: '40px' }}>
          <Box sx={{ fontWeight: '600', fontSize: '16px', lineHeight: '20px' }}>{rtp}</Box>
          <Box sx={{ fontWeight: '500', fontSize: '10px', lineHeight: '12px', color: '#818181' }}></Box>
        </Box>
        <Box onClick={() => navigateToProcPage(rtp)} sx={{ marginRight: '33px', cursor: 'pointer' }}>
          View
        </Box>
      </Box>
    ));
  const navigateToProcPage = (rtp: string) => {
    navigate(`/rtp/${rtp}`);
  };

  return (
    <Box
      sx={{
        background: '#fff',
        boxShadow: '0 3px 2px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        flexGrow: 1,
        padding: '18px 32px'
      }}
    >
      <Typography
        variant="h2"
        component="h2"
        sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '29px', marginBottom: '16px' }}
      >
        Regulatory Touch Points
      </Typography>
      <Box>{generateProcCard()}</Box>
    </Box>
  );
};

export default RtpCard;
