import React from 'react';
import { Box } from '@mui/material';

export interface ErrorPageProps {
  message?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ message = 'Default message' }) => {
  return <Box>{message}</Box>;
};

export default ErrorPage;
