import { BasicPolicyDataStructure } from '../interfaces/LandingPage';

export interface BusinessUnitCountType {
  'Business Units': number;
  Processes: number;
  Procedures: number;
}

export const countBusinessUnitType = (data: BasicPolicyDataStructure): BusinessUnitCountType => {
  const result = {
    'Business Units': 0,
    Processes: 0,
    Procedures: 0
  };
  result['Business Units'] = data.children.length;
  data.procs.forEach((proc) => {
    switch (proc.type) {
      case 'Process':
        result.Processes++;
        break;
      case 'Procedure':
        result.Procedures++;
        break;
    }
  });
  return result;
};
