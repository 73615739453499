import React, { useState } from 'react';
import styles from './styles.module.css';
import IconButton from '@mui/material/IconButton';
import Warn from '../../../icons/warn.svg';
import Right from '../../../icons/right.svg';
import Down from '../../../icons/down.svg';
import Box from '@mui/material/Box';
import { Framework } from '../../../interfaces/LandingPage';
import PolicyItem from './PolicyItem';

interface PolicyItemGroupProps {
  data: Framework;
}

const PolicyItemGroup: React.FC<PolicyItemGroupProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { name, policies } = props.data;

  return (
    <div className={styles.itemGroupDotted}>
      <div className={styles.item}>
        <div className={styles.description}>
          <h3>{name}</h3>
          {policies.length > 0 && <p className={styles.snippet}>{policies.length} Policies</p>}
        </div>
        <div>
          <img src={Warn} alt="warn" className={styles.warnIcon} />
          {policies.length > 0 ? (
            <IconButton color="primary" onClick={() => setOpen((open) => !open)} sx={{ width: 28 }}>
              {<img alt="open" src={open ? Down : Right} />}
            </IconButton>
          ) : (
            <Box sx={{ display: 'inline-block', width: 28 }} />
          )}
        </div>
      </div>
      {open && (
        <Box>
          {policies.map((policy, index) => (
            <PolicyItem key={index} groupName={name} data={policy} />
          ))}
        </Box>
      )}
    </div>
  );
};

export default PolicyItemGroup;
