import React from 'react';
import { DataModelParaStructure } from '../../interfaces/Rtp';
import UserInput from './UserInput';
import { Card, Typography } from '@mui/material';

interface UserInputBoardProps {
  parameters: Array<DataModelParaStructure>;
}

const UserInputBoard = ({ parameters }: UserInputBoardProps) => {
  return (
    <Card
      sx={{
        padding: '10px 32px',
        backgroundColor: '#ffffff',
        my: 1
      }}
    >
      <Typography variant="h2" mb={2}>
        Reasoner Input
      </Typography>
      {parameters.map((parameter, index) => {
        return <UserInput {...parameter} key={index} prefix={`${index + 1}`} />;
      })}
    </Card>
  );
};

export default UserInputBoard;
